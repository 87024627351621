import React, { ChangeEvent, useState } from 'react'
import arrow from '../assets/Arrow.svg'
import sideImage from '../assets/side.svg'
import beanzLogo from '../assets/Logo.svg'
import { config } from '../config';
import { Loader } from './Loader';
import confirmation from '../assets/Confirmation.svg'
import cancel from '../assets/Cancel.svg'



function DeleteAccount() {

  const isArabic = window.location.pathname.includes('/ar/delete-account');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const handleSuccessPopup = () => setShowSuccessPopup(!showSuccessPopup);
  const [countryCode, setCountryCode] = useState('+971');
  const handleCountryCodeChange = (value: string) => setCountryCode(value);

    
  const partialPhoneValidator = (value: string) => {
    const partialPhoneRegex = /^\+?\d{0,4}[-.\s]?\(?(\d{0,3})\)?[-.\s]?(\d{0,4})[-.\s]?(\d{0,4})[-.\s]?(\d{0,9})$/;
    return partialPhoneRegex.test(countryCode + value);
  };

  const phoneValidator = () => {
    const partialPhoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return partialPhoneRegex.test(countryCode + phone);
  };
  
  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    if(partialPhoneValidator(value))
      setPhone(value);
  }

  const goBack = () => {
    window.history.back(); 
  };

  const getRegion = () => {
    const REGIONS = {
    '+971': 'ae',
    '+968': 'om',
    '+974': 'qa'
  }
    for (const region of Object.keys(REGIONS)) {
      if (String(region) === String(countryCode)) {
        //@ts-ignore
        return REGIONS[region]
      }
    }
  }
  
  const reportIssue = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!phoneValidator()) {
      setError(isArabic? 'الرقم غير صحيح' : 'Phone format is not correct');
      return;
    }

    const data = {
      "category": "Other",
      "description": "I want to delete my account!",
      "attachments": [],
      "user": {
          "sub": "",
          "name": "",
          "mobile": countryCode + phone,
          "region": getRegion()
      }
    }
    setError('');
    setLoading(true);
    let response: Response | null = null;
    try {
      response = await fetch(`${config.baseUrl}/api/support/external`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'source-agent': 'internal-web-agent'
        },
        body: JSON.stringify(data)
      })
    } catch {
      setError(isArabic ? 'لم يتم استلام طبك' : 'Failed to send the request');
    } finally {
      setLoading(false);
      setPhone('')
    }

    if (response?.status === 200) {
      setShowSuccessPopup(true);
    } else {
      setError('Something Went Wrong.')
    }
  }
    return (<>
      {showSuccessPopup ? 
        <div id="popup" >
          <div id="popup-content">
            <img id="popup-close" src={cancel} onClick={handleSuccessPopup} />
            <img id="popup-confirmation" src={confirmation} />
            <p style={{ fontFamily: isArabic ? 'ArabicFontRegular' : 'EngRegular' }}>{isArabic ?
                  <>
                    شكرا لك على التواصل
                    <br />
                    لقد تم إعادة توجيه طلبك لفريق الدعم لدينا سوف نتواصل
                    <br />
                    معك على الفور لتأكيد عملية الحذف وبدئها
                  </> : 
                  <>
                    Thank you for reaching out. your request has been forwarded 
                    <br />  
                    to our support team. We will promptly connect with you to 
                    <br />
                    confirm and initiate the deletion process.</>}</p>
              <hr />
              <button onClick={goBack} style={{ fontFamily: isArabic ? 'ArabicFontRegular' : 'EngRegular' }}>{isArabic? 'العودة للموقع' : 'Back to website'}</button>
          </div>
        </div>
         : null
      }
        <div id="delete-container" className={isArabic? 'ar' : ''}>
          <img  id="delete-container-img" src={sideImage} />
          <div id="back-home" className={isArabic? 'ar' : ''}>
            <div id="back-home-content" onClick={goBack} >
              <img src={arrow} className={isArabic? 'ar' : ''}/>
              <h1 style={{ fontFamily: isArabic ? 'ArabicFontRegular' : 'EngRegular' }}>
                {isArabic? 'العودة للقائمة الرئيسية' : 'back to home page'}
              </h1>
            </div>
            <div id='delete-content' >
              <img src={beanzLogo} />
              <p style={{ fontFamily: isArabic ? 'ArabicFontRegular' : 'EngRegular' }}>{isArabic? 'حذف الحساب' : 'Delete Account'!}</p>
              <div id='delete-form'>
                <h3 >{isArabic? 'رقم الموبايل' : 'Enter your phone number'}</h3>
                <div id='delete-form-input' className={isArabic? 'ar' : ''}>
                  <select
                    style={{ fontFamily: isArabic ? 'ArabicFontRegular' : 'EngRegular' }}
                    value={countryCode}
                    className={isArabic? 'ar' : ''}
                    onChange={(e) => handleCountryCodeChange(e.target.value)}
                  >
                    <option value="+971">+971 (UAE)</option>
                    <option value="+968">+968 (OMAN)</option>
                    <option value="+974">+974 (QATAR)</option>
                  </select>
                  <input
                    style={{ fontFamily: isArabic ? 'ArabicFontRegular' : 'EngRegular' }}
                    value={phone}
                    onChange={handlePhoneChange}
                    type="text"
                    name="phone"
                    placeholder={isArabic ? 'ادخل رقم الموبايل' : '#Phone Number'}
                  />
                </div>
              <button onClick={reportIssue} style={{ fontFamily: isArabic ? 'ArabicFontBold' : 'EngBold' }}>{isArabic? 'ادخال' : 'Submit'}</button>
              </div>
              {error ? <span className='error-msg'>{error}</span> :
                  loading ? <Loader size={40} /> :
                    null
              }
            </div>
          </div>
        </div>
    </>)
   
}
export default DeleteAccount
