import React, { useEffect } from 'react'
import { Home } from '../components/Home'
import { useNavigate, useParams } from 'react-router-dom';

interface HomeProps {
  isArabic: boolean;
  handleLanguageChange: () => void;
  sectionTranslations: Record<string, string>;
  currentSection: string;

}
const HomeP = ({ isArabic, handleLanguageChange, sectionTranslations, currentSection }: HomeProps) => {

  function generateTermsUrl(isArabic: boolean, section: string): string {
    const languagePrefix = isArabic ? 'ar' : 'en';
    return `/${languagePrefix}`;
  }

  const navigate = useNavigate();
  const { section } = useParams();

  useEffect(() => {
    if (currentSection !== undefined) {
      const homeUrl = generateTermsUrl(isArabic, currentSection);
      navigate(homeUrl);
    }
  }, [isArabic, currentSection, navigate]);

  return (
    <div>
      <Home isArabic={isArabic} handleLanguageChange={handleLanguageChange} sectionTranslations={sectionTranslations} />
    </div>
  );
};


export default HomeP