import React, { useEffect, useState } from 'react';
import '../index.css';
import Logo from '../assets/Beanz logo.svg';
import locationn from '../assets/location.png'
import mail from '../assets/Email.png'
import { FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';

interface TypeProps {
  isArabic: boolean;
  handleLanguageChange: () => void;
}

export function Footer({ isArabic }: TypeProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLanguageChange = () => {
    const currentPath = location.pathname;
    const newPath = currentPath.includes('terms') ? (isArabic ? currentPath.replace('terms', 'الأحكام') : currentPath.replace('الأحكام', 'terms')) : currentPath;
    navigate(newPath);
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1100);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePrivacyLinkClick = () => {
    handleLanguageChange();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <footer className={`pt-20 bg-[#102E46] footer-web-background ${isMobile ? 'px-8' : 'px-32'}`}>
        <div className={`${isArabic ? 'footer-content-ar' : 'footer-content'}`}>
          <img
            src={Logo}
            width={isMobile ? 95 : 150}
            alt=""
            style={{
              marginBottom: isMobile ? '' : '12px',
            }}
          />
          <p className='text-[#D7D7D9]'
            style={{
              fontSize: isArabic ? (isMobile ? '' : '20px') : isMobile ? '12px' : '20px',
              fontFamily: isArabic ? (isMobile ? 'ArabicFontRegular' : 'ArabicFontLight') : isMobile ? 'EngRegular' : 'EngRegular',
            }}>
            {isArabic ? 'تطبيق بينز يبسط عملية الطلب المسبق لمحلات القهوة المختصة وبالتحديد في طلبات الاستلام الخارجي والطلبات الداخلية ويعزز الاحتفاظ بالعملاء من خلال برنامج الولاء في داخل التطبيق' : 'BEANZ App Simplifies Coffee Shop Ordering For In-Store, Curbside Or Dine-In, And Enhances Customer Retention With Customized Rewards.'}
          </p>
          {!isMobile && (
            <ul className="socials flex justify-around">
              <li><a href="https://www.instagram.com/beanz.ae/?igshid=MzRlODBiNWFlZA==" target='_blank'><i className="fa fa-facebook" /><FaInstagram size={23} /></a></li>
              <li><a href="https://www.linkedin.com/company/beanz-app/" target='_blank'><i className="fa fa-linkedin-square" /><FaLinkedinIn size={23} /></a></li>
            </ul>
          )}
        </div>
        <hr className={`text-[#D7D7D9] footer-hr ${isMobile ? 'hidden' : ''}`} />
        <div className={`${isArabic ? 'flex-row-reverse right-align footer-section-ar' : 'flex-row footer-section'}`}>
          <div className={`${isArabic ? 'footer-group-ar' : 'footer-group'}`}>
            <h3 className='text-[#335A79]'
              style={{
                fontSize: isArabic ? (isMobile ? '' : '20px') : isMobile ? '' : '20px',
              }}>{isArabic ? 'المقر الرئيسي' : 'HEAD OFFICE'}</h3>
            <div className='flex flex-col'>
              <ul className='flex flex-col'>
                <li>
                  <span className={`flex items-center ${isArabic ? 'flex-row-reverse right-align' : ''}`}>
                    <img src={locationn} alt="Location" width={14} height={14} className='mr-2' />
                    <a href="https://www.google.com/maps/place/United+Arab+Emirates/@24.0333337,54.3666678,12z/data=!4m2!4m1!3e0" target='_blank'>{isArabic ? 'الامارات - أبو ظبي' : 'UAE - Abu Dhabi'}</a>
                  </span>
                </li>
                <li>
                  <span className={`flex items-center ${isArabic ? 'flex-row-reverse right-align' : ''}`}>
                    <img src={mail} alt="Email" width={14} height={14} className='mr-2' />
                    <a href="mailto:support@beanz.ae">{isArabic ? 'support@beanz.ae' : 'support@beanz.ae'}</a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-group">
            <h3 className='text-[#335A79]'
              style={{
                fontSize: isArabic ? (isMobile ? '' : '20px') : isMobile ? '' : '20px',
              }}>{isArabic ? 'نزل التطبيق' : 'GET THE APP'}</h3>
            <a href="https://apps.apple.com/ae/app/beanz-coffee/id1532652635" target='_blank'>{isArabic ? 'App Store' : 'App Store'}</a>
            <a href="https://play.google.com/store/apps/details?id=ae.beanz.client" target='_blank'>{isArabic ? 'Google App' : 'Google App'}</a>
          </div>
          <div className="footer-group">
            <h3 className='text-[#335A79]'
              style={{
                fontSize: isArabic ? (isMobile ? '' : '20px') : isMobile ? '18px' : '20px',
              }}>{isArabic ? 'مركز الخدمة' : 'HELP CENTER'}</h3>
            <Link to={`/${isArabic ? 'ar' : 'en'}/terms`} onClick={() => { handlePrivacyLinkClick(); handleLanguageChange(); }}>{isArabic ? 'الأحكام والشروط' : 'Terms & Conditions'}</Link>
            <Link to={`/${isArabic ? 'ar' : 'en'}/privacy`} onClick={() => { handlePrivacyLinkClick(); handleLanguageChange(); }}>{isArabic ? 'سياسة الخصوصية' : 'Privacy Policy'}</Link>
            <Link to={`/${isArabic ? 'ar' : 'en'}/delete-account`} onClick={() => { handlePrivacyLinkClick(); handleLanguageChange(); }}>{isArabic ? 'حذف حسابي' : 'Delete my account'}</Link>
          </div>
          {isMobile ? (
            <div className="footer-group">
              <h3 className="text-[#335A79]"
                style={{
                  fontSize: isArabic ? (isMobile ? '' : '20px') : isMobile ? '18px' : '20px',
                  fontFamily: isArabic ? (isMobile ? '' : '') : isMobile ? 'EngBold' : 'EngBold',
                }}>
                {isArabic ? 'تابعنا على' : 'FOLLOW US'}
              </h3>
              <ul className="socials flex justify-center">
                <li>
                  <a href="https://www.instagram.com/beanz.ae/?igshid=MzRlODBiNWFlZA==" target='_blank'>
                    <FaInstagram size={18} />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/beanz-app/" target='_blank'>
                    <FaLinkedinIn size={18} />
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
        <hr className='text-[#D7D7D9] footer-hr' />
        <div className="footer-bottom">
          <p className='text-[#D7D7D9]'
            style={{
              fontFamily: isArabic ? (isMobile ? 'ArabicFontLight' : 'ArabicFontLight') : isMobile ? 'EngRegular' : 'EngRegular',
            }}>
            <span className='text-[#335A79]'
              style={{
                fontFamily: isArabic ? (isMobile ? 'ArabicFontSemi' : 'ArabicFontSemi') : isMobile ? 'EngSemi' : 'EngSemi',
              }}>BEANZ - </span>
            {isArabic ? ' Ⓒ 2021 جميع الحقوق محفوظة' : 'All Rights Are Reserved Ⓒ 2021'}
          </p>
        </div>
      </footer>
    </>
  );
}